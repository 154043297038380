main {
    margin-left: 200px;
    background-color: var(--first-color);
    display: flex;
    flex-direction: column;
    padding-left: 3rem;
    flex-grow: 1;
    color: #808e9b;
}

main .buttons-scroll-conatiner {
    position: fixed;
    bottom: 2rem;
    right: 4rem;
    cursor: pointer;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    gap: 3px;
    z-index: 1;
}

main .up-button {
    font-size: 3rem;
    color: #808e9b9e;
}

@media (max-width: 768px) {
    main {
        padding-left: 0;
        margin-left: 0;
    }

    main .buttons-scroll-conatiner {
        display: none;
    }
}