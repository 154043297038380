:root {
    --skill-bar-lenght: 0%;
}

#skills-page {
    width: 100%;
    min-height: 100vh;
    color: var(--text-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem 2rem 4rem 0;
}



#skills-page h2 {
    padding-left: 1rem;
    font-size: 2rem;
    width: 100%;
    height: min-content;
    align-self: center;
    display: none;
}


#skills-page .skills-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 49%;
}


#skills-page .soft-skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    min-width: 500px;
    height: 600px;
    padding: 2rem 0 0 2rem;
    border-radius: 15px;
    background-color: var(--second-color);
    color: #808e9b;
    box-shadow: 5px 5px 5px black;
    gap: 1rem;
}


#skills-page .soft-skills h3 {
    width: 100%;
    font-size: 1.3rem;
    text-align: center;
}

#skills-page .soft-skills p {
    font-style: italic;
    text-align: start;
}

#skills-page .soft-skills .soft-sklls-contain {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 1rem;
    font-weight: bold;
}


#skills-page .soft-skills .soft-sklls-contain span {
    width: 1rem;
    height: 1rem;
    background-color: var(--green-color);
    border-radius: 50%;
}


#skills-page .tecnologies-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 45%;
    height: 600px;
    min-width: 500px;
    max-width: 700px;
    gap: 3rem;
    padding: 2rem 1rem 3rem 3rem;
    border-radius: 15px;
    background-color: var(--second-color);
    color: #808e9b;
    box-shadow: 5px 5px 5px black;
    overflow: scroll;

}


#skills-page .tecnologies-container h3 {
    width: 100%;
    text-align: center;
}


.skills__item {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: .7rem;
    flex-wrap: wrap;
}

.skills__item .technologies-icon {
    font-size: 2.5rem;
    color: var(--text-color);
}


.skills__bar,
.skills__bar::after {
    position: relative;
    width: 300px;
    height: 8px;
    background-color: #485460;
    border-radius: 10px;
    animation: skills-bar-fill 2s ease-in-out forwards;
    overflow: hidden;
}


.skills__bar::after {
    content: '';
    position: absolute;
    left: -300px;
}


.blue::after {
    background-color: #ef5777;
}

.red::after {
    background-color: #ffa801;
}

.orange::after {
    background-color: #ff5e57;
}

.violet::after {
    background-color: #3c40c6;
}

.green::after {
    background-color: #00d8d6;
}

.php-color::after {
    background-color: #ffde57;
}

.python-color::after {
    background-color: #777BB3;
}

.skills__bar--10::after {
    --skill-bar-lenght: 10%;
}


.skills__bar--20::after {
    --skill-bar-lenght: 20%;
}

.skills__bar--30::after {
    --skill-bar-lenght: 30%;
}

.skills__bar--40::after {
    --skill-bar-lenght: 40%;
}

.skills__bar--50::after {
    --skill-bar-lenght: 50%;
}


.skills__bar--60::after {
    --skill-bar-lenght: 60%;
}


.skills__bar--70::after {
    --skill-bar-lenght: 70%;
}


.skills__bar--80::after {
    --skill-bar-lenght: 80%;
}


.skills__bar--90::after {
    --skill-bar-lenght: 90%;
}


@keyframes skills-bar-fill {
    to {
        transform: translateX(var(--skill-bar-lenght))
    }
}

@media (max-width: 768px) {
    #skills-page {
        padding: 160px 0 3rem 0;
        gap: 2rem;
        justify-content: flex-start;
    }

    #skills-page h2 {
        font-size: 1.5rem;
        display: inline;
        padding-left: .5rem;
    }


    #skills-page .soft-skills,
    #skills-page .tecnologies-container {
        max-width: none;
        width: 100vw;
        min-width: none;
        box-shadow: none;
        padding: 2rem 1rem;
        height: min-content;
        flex-direction: column;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        padding-left: 0;
    }

    #skills-page .skills-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100vw;
        min-width: 100vw;
    }

    #skills-page .tecnologies-container {
        min-width: 200px;
        padding: 2rem 0 3rem;
    }

    #skills-page .soft-skills {
        min-width: 100vw;
        height: 100%;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 2rem;
    }

    #skills-page .soft-skills h3 {
        text-align: center;
        width: 100vw;
    }


    #skills-page .soft-skills .skills__item {
        width: 200px;
    }

    #skills-page .soft-skills p {
        width: 100%;
    }

    .skills__item {
        gap: 1rem;
        justify-content: center;
        align-items: center;
        width: 80%;
    }

    .skills__item p {
        text-align: center;
        justify-content: center;
    }

    .skills__bar,
    .skills__bar::after {
        width: 450px;
        height: 15px;
        /* overflow: hidden; */
    }

    .skills__bar::after {
        content: '';
        position: absolute;
        left: -450px;
    }
}

@media (max-width: 468px) {

    .skills__bar,
    .skills__bar::after {
        width: 350px;
        height: 15px;
        /* overflow: hidden; */
    }

    .skills__bar::after {
        content: '';
        position: absolute;
        left: -350px;
    }

    .skills__item {
        width: 100%;
    }
}