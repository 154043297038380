header {
    width: 200px;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    background-color: var(--second-color);
    position: fixed;
    z-index: 9;
    color: var(--letter-color);
    padding-bottom: 1rem;
    padding-top: 4rem;
    overflow: hidden;
}

header .code-icon {
    font-size: 6rem;
    cursor: pointer;
    color: var(--green-color);
}


.menu-bar {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-bar:first-child {
    border-top: 1px solid #485460;
}


.menu-bar li {
    border-bottom: 1px solid #485460;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    cursor: pointer;
    padding: 1.2rem 0;
    text-decoration: none;
    font-family: 'Trispace', sans-serif;
    font-weight: bold;
}


.menu-bar li:hover {
    animation: menu-animation .2s ease-in-out forwards;
}

@keyframes menu-animation {
    100% {
        transform: scale(1.05) translateY(.2rem);
    }

}


.logos-rss-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.logo:hover,
.myName:hover {
    animation: menu-animation .2s ease-in-out forwards;
}

.logo {
    width: 40px;
    height: 40px;
    color: var(--letter-color);
    cursor: pointer;
}


header .myName {
    width: 100%;
    font-size: 1.1rem;
    font-weight: bolder;
    cursor: pointer;
    font-family: 'Trispace', sans-serif;
    text-align: center;
}



@media (max-width: 768px) {
    header {
        right: 0;
        left: 0;
        top: 0;
        width: 100vw;
        height: 165px;
        padding: 1rem;
        justify-content: space-between;
        justify-content: space-between;
    }

    header .code-icon {
        display: none;
    }

    .menu-bar {
        display: none;
    }

    .logos-rss-container {
        padding: 0;
        gap: .5rem;
    }
}