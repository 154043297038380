/* Estilos generales */
#works-page {
    width: 100%;
    /* min-height: 100vh; */
    color: var(--letter-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem; /* Reducir la separación entre el slider y el siguiente componente */
    padding: 2rem;
}

#works-page h2 {
    font-size: 2rem;
    display: none; /* Mostrar si es necesario */
}

/* Swiper */
.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease-in-out; /* Añadir transición para un movimiento más suave */
}

/* Tarjeta de proyecto */
.work-card {
    width: 90%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 2rem; /* Aumentar la separación entre los elementos de la tarjeta */
    border-radius: 15px;
    background-color: var(--second-color);
    box-shadow: 8px 8px 15px var(--second-color);
    padding: 2rem;
    transition: transform 0.3s ease-in-out; /* Añadir transición para animación suave al hacer hover */
}

.header-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.header-card span {
    font-weight: bolder;
    font-size: 2.5rem;
    text-align: center;
}

.links-container {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.logo {
    font-size: 2rem;
    cursor: pointer;
}

.work-card img {
    width: 100%;
    border-radius: 15px;
}

.work-card p {
    text-align: center;
    padding: 0 1.5rem;
}

/* Estilo de los puntos de paginación */
.swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: var(--letter-color);
    opacity: 0.8;
}

.swiper-pagination-bullet-active {
    background: var(--primary-color);
    opacity: 1;
}

/* Responsividad */
@media (max-width: 768px) {
    .work-card {
        width: 90%;
        padding: 1rem;
    }

    .header-card span {
        font-size: 1.5rem;
    }

    #works-page h2 {
        display: block;
    }
}