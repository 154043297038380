* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  display: flex;
  font-family: 'Trispace', sans-serif;
  flex-direction: column;
}

h2 {
  color: #000;
  font-weight: bold;
}