#about-page {
    width: 100%;
    min-height: 100vh;
    color: var(--letter-color);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem 0 4rem 0;
}

#about-page h2 {
    font-size: 2rem;
    width: 100%;
    display: none;
}

#about-page article.content {
    min-width: 600px;
    width: 65%;
    min-height: 60%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}


#about-page article.content p {
    width: 100%;
    height: 75%;
    padding-left: 1.5rem;
    font-size: 1.2rem;
    letter-spacing: 3px;
    line-height: 50px;
    color: var(--letter-color);
}

#about-page .avatar-container {
    display: flex;
    width: 350px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border: 2.5px double #808e9b;
    padding: 1rem 3rem 3rem;
    border-radius: 15px;
    background-color: var(--second-color);
    box-shadow: 5px 5px 5px var(--second-color);
}

#about-page .hobbies-container {
    width: 300px;
    height: min-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem;
    gap: 1rem;
    border: 2px solid var(--letter-color);
    border-radius: 15px;
}


#about-page .face-me {
    width: 300px;
    height: 300px;
    background-image: url('../assets/png/face-me.png');
    background-size: cover;
    border-radius: 25px;
    border: 2px solid var(--letter-color);

}




#about-page .hobbies-container .icon {
    width: 50px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--letter-color);
    border-radius: 5px;
    padding: .2rem;
}


@media (max-width: 768px) {
    #about-page {
        padding: 150px 0 0 .5rem;
        justify-content: flex-start;
    }

    #about-page h2 {
        font-size: 1.5rem;
        display: inline;
    }

    #about-page article.content {
        min-width: 100vw;
        padding-left: 0;
        margin: 0;
    }

    #about-page article.content p {
        padding-left: .5rem;
    }

    #about-page .avatar-container {
        width: 100%;
        box-shadow: none;
        border: none;
        background-color: transparent;
    }

    #about-page .face-me {
        display: none;
    }

    #about-page .hobbies-container {
        display: none;
    }
}