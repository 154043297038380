#presentation-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#presentation-page .message-container {
    width: 100%;
    margin-top: 17rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 2rem;
}


#presentation-page .message-container .Typewriter {
    min-height: 250px;
}


#presentation-page .message-container span {
    font-size: 4rem;
    text-overflow: ellipsis;
}

#presentation-page p {
    height: min-content;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    color: var(--letter-color);
}



#presentation-page .contact-button-landing {
    bottom: 10rem;
    text-decoration: none;
    border: 1.5px solid #808e9b;
    border-radius: 15px;
    color: var(--letter-color);
    width: 200px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    font-weight: bolder;
}


#presentation-page .contact-button-header {
    display: none;
}

#presentation-page .contact-button-landing:hover {
    color: var(--green-color);
    border: 2px solid var(--green-color);
    transition: all 300ms;
}

@media (max-width: 768px) {
    #presentation-page {
        height: 100vh;
        min-height: 500px;
        justify-content: center;
        padding-left: .5rem;
        padding-top: 4rem;
    }

    #presentation-page .message-container {
        height: 50%;
        margin: 100px 0 0 0;
        padding: 0;
    }


    #presentation-page .message-container .Typewriter {
        height: 200px;
    }

    #presentation-page .message-container span {
        font-size: 2.5rem;
        line-height: 75px;
    }

    #presentation-page p {
        font-size: 1.2rem;
    }

    #presentation-page .contact-button-landing {
        display: none;
    }
}